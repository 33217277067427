import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Paginate from "../components/paginate"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

export const data = graphql`
  query businessPlanPostsQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fileAbsolutePath: { regex: "/strategic-business-plans/" } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          excerpt
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    file(relativePath: { eq: "business-planning.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const BusinessPlansPage = ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <Layout>
      <SEO title="Strategic Business Plans" />
      <Hero
        pageTitle="Strategic Business Plans"
        breadcrumbText="Services"
        breadcrumbLink="/#services"
      />

      {isFirst && (
        <section id="result" className="section-4">
          <div className="container">
            <div className="row intro">
              <div className="col-lg-8">
                <span className="pre-title m-0">
                  More Experience than any other firm
                </span>
                <h2>
                  Strategic{" "}
                  <span className="featured">
                    <span>Business Plans</span>
                  </span>
                </h2>
                <p className="text-max-800">
                  Strategic Airport Business Plans are designed to improve an
                  airport’s financial performance, economic development, and
                  operation. Airport sponsors are continually exploring revenue
                  producing opportunities including development of airport real
                  property reserves and concession development.
                </p>
                <p>
                  While the overall airport master plan guides the general
                  direction and scope of airport development, there is
                  frequently a need to see how the airport fits into its
                  economic environment, its competitive environment, and its
                  market niche. Many times, these studies will identify
                  alternative sources of revenue and new marketing
                  opportunities.
                </p>
                <Link to="/contact">
                  Contact us about Strategic Business Plans
                </Link>
              </div>
              <div className="col-lg-4 mt-5">
                <div className="d-none d-lg-block">
                  <Img fluid={data.file.childImageSharp.fluid} />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section id="result" className="section-1 offers">
        <div className="container">
          <div className="row items">
            {data.allMarkdownRemark.edges.map(edge => {
              return (
                <div
                  key={edge.node.id}
                  data-aos="fade-up"
                  className="col-sm-6 col-md-4 col-lg-3 item"
                >
                  <div className="card py-4 px-2">
                    <div className="col-12">
                      <Link
                        to={`/strategic-business-plans${edge.node.fields.slug}`}
                      >
                        <Img
                          fluid={
                            edge.node.frontmatter.image.childImageSharp.fluid
                          }
                          alt={edge.node.frontmatter.title}
                          className="logo"
                        />

                        <h5>{edge.node.frontmatter.title}</h5>
                      </Link>
                      <p className="small-text">{edge.node.excerpt}</p>
                      <Link
                        to={`/strategic-business-plans${edge.node.fields.slug}`}
                      >
                        <p className="small-text">Read more</p>
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="row d-flex justify-content-center pt-5">
            <hr />
            <Paginate
              isFirst={isFirst}
              isLast={isLast}
              nextPage={nextPage}
              prevPage={prevPage}
              currentPage={currentPage}
              numPages={numPages}
              postType="/strategic-business-plans/"
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BusinessPlansPage
