import React from "react"
import { Link } from "gatsby"

const Paginate = ({
  isFirst,
  isLast,
  nextPage,
  prevPage,
  currentPage,
  numPages,
  postType,
}) => {
  return (
    <nav aria-label="Page navigation">
      <ul className="pagination">
        <li className={isFirst ? "page-item disabled" : "page-item"}>
          <Link className="page-link" rel="prev" to={`${postType}${prevPage}`}>
            <span aria-hidden="true">&lsaquo;</span>
            <span className="sr-only">Previous</span>
          </Link>
        </li>

        {Array.from({ length: numPages }, (_, i) => (
          <li
            key={`pagination-number${i + 1}`}
            className={i + 1 === currentPage ? "page-item active" : "page-item"}
          >
            <Link
              className="page-link"
              to={`${postType}${i === 0 ? "" : i + 1}`}
            >
              {i + 1}
            </Link>
          </li>
        ))}

        <li className={isLast ? "page-item disabled" : "page-item"}>
          <Link className="page-link" rel="next" to={`${postType}${nextPage}`}>
            <span aria-hidden="true">&rsaquo;</span>
            <span className="sr-only">Next</span>
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Paginate
